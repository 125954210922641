/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
    height: 100%;
    font-family: "Noto Sans Thai", sans-serif;
}

body {
    @apply text-black font-noto;
}

.content {
    @apply px-8 py-8;
}

.card {
    @apply bg-white rounded-xl w-full shadow-sm p-6;
}

.h-header {
    @apply h-[3.5rem];
}

* {
    font-family: "Noto Sans Thai", sans-serif !important;
}

h1 {
    @apply text-xl font-bold
}

.btn {
    @apply h-11 px-5 py-1.5 rounded-lg text-base
}

.btn-sm {
    @apply h-9 px-5 py-1.5 rounded-lg text-sm
}

.btn-lg {
    @apply h-12 px-5 py-1.5 rounded-lg text-lg
}

.btn-primary {
    @apply bg-primary-500 text-white hover:bg-primary-600 transition ease-in-out duration-300 disabled:opacity-60;
}

.btn-secondary {
    @apply bg-secondary-900 text-white hover:bg-secondary-700 transition ease-in-out duration-300 disabled:opacity-60;
}

.btn-light {
    @apply bg-gray-100 border-gray-200 hover:bg-gray-300 hover:border-gray-400 border transition ease-in-out duration-300 disabled:opacity-60;
}

.btn-success {
    @apply bg-lime-500 text-white hover:bg-lime-600 transition ease-in-out duration-300 disabled:opacity-60;
}

.btn-warning {
    @apply bg-orange-500 text-white hover:bg-orange-600 disabled:opacity-60;
}

.btn-danger {
    @apply bg-red-500 text-white hover:bg-red-600 disabled:opacity-60;
}

.label {
    @apply py-0.5 px-2 rounded-md text-xs;
}

.label-primary {
    @apply bg-primary-400 text-white;
}

.label-secondary {
    @apply bg-secondary-500 text-white;
}

.label-light {
    @apply bg-gray-100;
}

.label-success {
    @apply bg-lime-500 text-white;
}

.label-warning {
    @apply bg-orange-500 text-white;
}

.label-danger {
    @apply bg-red-500 text-white;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
    @apply !rounded-lg;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    @apply !bg-white rounded-lg;
}

.mat-form-field {
    @apply !border-0 !h-fit;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    @apply !p-0 !min-h-fit !h-fit;
}

.mat-mdc-form-field-focus-overlay {
    @apply bg-primary-400 bg-opacity-0;
}

.mat-mdc-menu-panel {
    @apply !rounded-lg mt-2 !max-w-[360px];
}

.mat-mdc-select-panel {
    @apply !rounded-lg mt-2;
}

.mdc-text-field {
    padding: 0 0px !important;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
    width: 0 !important;
}

.mat-mdc-form-field-bottom-align::before {
    @apply !hidden;
}

.mat-mdc-select-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.mat-mdc-menu-item:hover {
    @apply text-primary-400;
}

mat-select {
    @apply bg-white flex border border-gray-200 focus:border-2 focus:border-primary-400 rounded-lg px-4 h-9 !text-sm outline-none placeholder:text-sm;
}

mat-option {
    @apply !text-sm;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    @apply !bg-white;
}

.mat-mdc-select-placeholder {
    @apply text-sm;
}

.mat-datepicker-content {
    @apply !rounded-lg;
}

.mat-datepicker-popup {
    @apply mt-2;
}

.mat-datepicker-content .mat-calendar {
    @apply !min-h-[367px] !min-w-[296px];
}


/* INPUT START */

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
}

input[type="color"] {
    @apply border-none w-full h-9 rounded-lg cursor-pointer appearance-none shadow;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    @apply p-0;
}

input[type="color"]::-webkit-color-swatch {
    @apply border-none;
}

[type="radio"]:checked,
[type="checkbox"]:checked {
    @apply bg-primary-500 border-primary-500 bg-cover bg-no-repeat;
    background-position: 50%;
}

[type="radio"] {
    @apply appearance-none inline-block w-5 h-5 p-0 rounded-full bg-white border border-gray-300 text-current hover:shadow-md shadow-black align-middle select-none disabled:bg-gray-300 disabled:border-gray-500 disabled:shadow-none;
    -webkit-print-color-adjust: exact;
}

[type="radio"]:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23FFF' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}

[type="checkbox"] {
    @apply appearance-none inline-block w-5 h-5 p-0 rounded bg-white border border-gray-300 text-current hover:shadow-md shadow-black align-middle select-none disabled:bg-gray-300 disabled:border-gray-500 disabled:shadow-none;
    -webkit-print-color-adjust: exact;
}

[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23FFF' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}

.input-invalid {
    @apply bg-white border !border-red-500 text-gray-900 rounded-lg block w-full p-2.5 h-9 text-sm focus:ring-2 focus:ring-red-400 focus:outline-none outline-red-400 placeholder:text-sm;
}

.input {
    @apply bg-white border border-gray-200 text-gray-900 rounded-lg block w-full p-2.5 h-9 text-sm focus:ring-2 focus:ring-primary-500 focus:outline-none disabled:bg-gray-100 disabled:cursor-not-allowed placeholder:text-sm;
}

.text-area {
    @apply bg-white border border-gray-200 text-gray-900 rounded-lg block w-full p-2.5 min-h-9 text-sm disabled:bg-gray-100 disabled:cursor-not-allowed;
}

.error-message {
    @apply text-xs text-red-500 pt-1
}

/* INPUT END */

/* TABLE START */

.div-table {
    @apply w-full overflow-auto mb-4 block bg-white rounded-lg border;
}

table {
    @apply w-full;
}

.thead-tr {
    @apply h-10 bg-gray-100 font-semibold;
}

.tbody-tr {
    @apply bg-white border-b h-8 hover:bg-gray-50 transition-colors duration-150;
}

th {
    @apply px-4 py-3.5 text-sm border-b font-bold;
}

td {
    @apply max-h-fit h-8 px-4 py-2.5 text-sm border-b;
}

/* Table END */

.mdc-tooltip__surface.mdc-tooltip__surface-animation {
    @apply p-2 !text-sm !bg-black !bg-opacity-80;
    /* @apply !bg-white !text-black border p-3 !rounded-none min-w-64 !text-sm */
}